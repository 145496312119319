import React from 'react';
import { CookiesBanner } from '@strikelabs/luna';
import PropTypes from 'prop-types';
import { useWindowScroll } from 'react-use';

import { useGlobalStore } from 'provider';
import { DISMISSED_COOKIE } from 'provider/actions';
import { Container } from './style';

const CookieBanner = () => {
  const { y } = useWindowScroll();

  const { dispatch } = useGlobalStore();

  const dismissCookieCallback = () => {
    dispatch({
      type: DISMISSED_COOKIE,
      payload: { cookieDismissed: true },
    });
  };

  return (
    <Container hide={y > 300}>
      <CookiesBanner
        url="/legal/privacy-policy"
        dismissCallback={dismissCookieCallback}
      />
    </Container>
  );
};

CookieBanner.propTypes = {
  url: PropTypes.string.isRequired,
};

CookieBanner.defaultProps = {
  url: '/legal/privacy-policy',
};

export default CookieBanner;
