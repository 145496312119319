import { get } from 'tiny-cookie';
import { DISMISSED_COOKIE } from './actions';
import { isClient } from '@strikelabs/pax';

export const COOKIE_NAME = 'hs_cookies_dismissed';
export const COOKIE_VALUE = 'dismissed';

const getInitialStrikeCookieState = () =>
  isClient ? get(COOKIE_NAME) === COOKIE_VALUE : false;

const initialState = {
  cookieDismissed: getInitialStrikeCookieState(),
};

const reducer = (state, action) => {
  switch (action.type) {
    case DISMISSED_COOKIE: {
      return {
        ...state,
        ...action.payload,
      };
    }
  }
};

export { initialState };
export default reducer;
