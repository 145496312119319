import styled from 'styled-components';
import { rem } from 'polished';

export const Container = styled.div`
  position: fixed;
  top: ${({ hide }) => (hide ? '-100px' : '81px')};
  transition: all 0.2s ease-in-out;
  left: 0;
  width: 100%;
  z-index: ${({ hide }) => (hide ? '1' : '9999')};

  ${({ theme }) => theme.media.tablet} {
    top: ${({ hide }) => (hide ? '0' : '83px')};
  }

  ${({ theme }) => theme.media.giant} {
    bottom: 0;
    top: unset;
  }

  div {
    box-shadow: 0px ${rem(2)} ${rem(3)} 0px rgba(0, 0, 0, 0.05);

    ${({ theme }) => theme.media.giant} {
      box-shadow: 0px ${rem(-2)} ${rem(3)} 0px rgba(0, 0, 0, 0.05);
    }
  }
`;
