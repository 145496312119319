import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
  }

  body {
    margin: 0 auto;
    overflow-x: hidden;
    background: ${({ theme }) => theme.colors.background};
    font-size: ${({ theme }) => theme.defaults.fontSize};
    font-family: ${({ theme }) => theme.defaults.fontFamily};
  }

  h1, h2, h3, h4, h5, h6, span, a, p {
    margin: 0;
    line-height: 1;
    color: inherit;
  }

  .async-hide {
    opacity: 0;
  }
`;

export const TableResetStyles = createGlobalStyle`
  table {
    border-collapse: collapse;
    width: 100%;
  }

  th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  th {
    background-color: #f2f2f2;
  }

  tr:hover {
    background-color: #f5f5f5;
  }
`;

export default GlobalStyle;
