import { themes } from '@strikelabs/luna';
import { rem } from 'polished';

const themeV2 = {
  ...themes.base,
  colors: {
    ...themes.base.colors,
    backgroundDark: '#1B2021',
    panel: '#2F2F2F',
    imgAccent: '#f5f3f1',
  },
  typography: {
    ...themes.base.typography,
    hero: {
      fontSize: rem(38),
      fontWeight: 700,
      lineHeight: rem(42),
      letterSpacing: '0.4px',
      color: themes.base.colors.headingText,
      [themes.base.media.tablet]: {
        fontSize: rem(42),
        lineHeight: rem(48),
      },
      [themes.base.media.desktop]: {
        fontSize: rem(76),
        fontWeight: 500,
        lineHeight: rem(90),
        letterSpacing: '-0.76px',
      },
    },
    h1: {
      fontSize: rem(38),
      fontWeight: 700,
      lineHeight: rem(42),
      letterSpacing: '-0.4px',
      color: '#191919',
      [themes.base.media.tablet]: {
        fontSize: rem(42),
        lineHeight: rem(48),
        letterSpacing: '-0.5px',
      },
      [themes.base.media.desktop]: {
        fontSize: rem(53),
        lineHeight: rem(67),
        letterSpacing: '-0.38px',
      },
    },
    h2: {
      fontSize: rem(24),
      fontWeight: 700,
      lineHeight: rem(30),
      letterSpacing: '-0.1px',
      color: '#201F1F',
      [themes.base.media.tablet]: {
        fontSize: rem(32),
        lineHeight: rem(42),
        letterSpacing: '-0.1px',
      },
      [themes.base.media.desktop]: {
        fontSize: rem(40),
        fontWeight: 500,
        lineHeight: rem(52),
        letterSpacing: '-0.22px',
      },
    },
    h3: {
      fontSize: rem(20),
      fontWeight: 400,
      lineHeight: rem(26),
      letterSpacing: '-0.1px',
      color: themes.base.typography.h3.color,
      [themes.base.media.tablet]: {
        fontSize: rem(22),
        lineHeight: rem(32),
        letterSpacing: '-0.14px',
      },
      [themes.base.media.desktop]: {
        fontSize: rem(22),
        lineHeight: rem(34),
        letterSpacing: '-0.23px',
      },
    },
  },
  maxWidth: rem(1440),
  defaults: {
    ...themes.base.defaults,
    halfGutterSpacing: '12px',
  },
};

export default themeV2;
