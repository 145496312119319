import React, { useReducer, useContext } from 'react';
import PropTypes from 'prop-types';

import reducer, { initialState } from './reducer';

export const GlobalContext = React.createContext(initialState);

const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <GlobalContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

GlobalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useGlobalStore = () => useContext(GlobalContext);
export default GlobalProvider;
